.arrowNavigationBar {
  position: absolute;
  display: flex;
  justify-content: end;
  width: 95%;
  top: 45%;
  right: 0%;
  z-index: 9999;
  left: 2%;
  flex-direction: column-reverse;
  align-items: end;
  gap: 20px;
}

img.firstArrow {
  transform: rotate(90deg) !important;
}

img.SecondArrow {
  transform: rotate(270deg) !important;
}

.arrowNavigationBar img {
  width: 26px;
  z-index: 999999999;
  background: #ffffff85;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}

.video-player {
  object-fit: cover !important;
  border-radius: 20px !important;
  position: relative;
  height: 570px;
  width: 360px;
  margin: -40px 0px;
}

.KlickPage {
  overflow: hidden;
  height: 100vh;
}

.video-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

video {
  border-radius: 5px;
  scroll-snap-align: start;
}

.video-wrapper-content {
  width: 360px;
  height: auto;
  margin: auto;
  position: absolute;
  bottom: 0px;
  padding: 30px;
  color: #fff;
  font-family: "Montserrat";
}

.video-wrapper-content p {
  margin: 0;
}

.profileDp {
  width: 60px;
  border-radius: 90px;
  overflow: hidden;
  height: 60px;
  border: 3px solid #dd2477;
}

.profileDp img {
  height: inherit;
}

.profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.followBtn {
  color: #fff;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  padding: 7px 25px;
  margin: 10px 0px;
  display: inline-block;
  border-radius: 40px;
}

.videoDescription span {
  font-weight: 600;
  font-size: 15px;
}

.videoDescription {
  font-size: 14px;
}

.video-wrapper {
  display: flex;
  margin-top: 80px;
  /* margin-bottom:80px; */
}

.videoOptionPanel {
  padding-top: 95%;
  margin-left: 19px;
}

.videoOptionPanel div {
  margin: 30px 0px;
}

.videoOptionPanel div p {
  margin-bottom: -10px;
  margin-top: 5px;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
}

.ShareIcon {
  margin: 5px;
  width: 40px;
}

.copied-text {
  font-family: "Montserrat";
  color: green;
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}

.copied-text.hide {
  opacity: 0;
}

.klickclick {
  cursor: pointer;
}

.shareBox {
  width: 450px;
  max-width: 700px;
}

.shareBox form {
  display: flex;
  gap: 10px;
}

.shareBox form input {
  display: flex;
  margin: 0px;
  gap: 30px;
}

.shareBox form button {
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  padding: 10px;
  width: 100px;
  text-align: center;
  border-radius: 30px;
  border: none;
  color: #fff;
}

.klickPageHeader {
  background-color: #2c0e1ebf;
  backdrop-filter: blur(0px);
  transition: background-color 1 ease;
  padding: 10px 0px;
  height: 84px;
}

.klickPageHeader header {
  padding: 0px 0px !important;
}

.klickPageHeader .side_panel_head_profile_img {
  width: 60%;
}
