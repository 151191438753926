@font-face {
  font-family: "Quiche Display Regular";
  src: url("assets/fonts/quiche-display-regular.woff2") format("woff2"),
    url("assets/fonts/quiche-display-regular.woff") format("woff");
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Quiche Display Regular", "Montserrat";
}

/*-- Form Page --*/

a {
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 500;
}

.Verify_link .btn-trans {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 14px;
}

.btn-trans {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 500;
  color: white;
  gap: 10px;
  display: flex;
  align-items: center;
}

form a {
  text-decoration: none;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat";
  font-weight: 500;
}

.forgot-password-form p {
  font-family: "Montserrat";
  font-size: 16px;
}

.error-msg {
  font-family: "Montserrat";
  gap: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #f2663b;
  margin-top: -12px;
  margin-bottom: 15px;
}

.videoDescription span + span span {
  background: linear-gradient(45deg, #dd2477, #f36e36);
  cursor: pointer;
  padding: 0px 12px;
  border-radius: 10px;
  font-size: 11px;
}

.error-msg span {
  font-weight: 700;
  font-family: "Montserrat";
}

.MyProfile .error-msg {
  margin-top: 5px;
  margin-bottom: 0px;
}

.wrapper {
  position: relative;
  display: flex;
  border: 1px solid #dd2477;
  padding: 10px 40px 10px 10px;
  border-radius: 40px;
  background: #fff1ef;
  color: #f2663b;
}

.wrapper p {
  margin: 0px;
}

.close:after {
  content: "✕";
}
.close {
  position: absolute;
  cursor: pointer;
}

.success-msg {
  font-family: "Montserrat";
  gap: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: rgb(38, 126, 4);
  margin-top: -12px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.success-msg span {
  font-weight: 700;
  font-family: "Montserrat";
}

.login-logo {
  width: 78%;
  /* margin-bottom: calc(100% / 11); */
}
.login-logo img {
  width: 80%;
}
.login-form {
  padding: 0px 0px 20px 0;
}
.login_form {
  display: flex;
}
.login_left_sec {
  width: 50%;
}
.login_right_sec {
  width: 50%;
  padding: 20px 155px;
}
.innr_frm {
  padding: 0 0px 0px;
}
.innr_frms {
  padding: 0 90px;
}
.login_left_sec img {
  width: 100%;
}
.login-form h1 {
  font-size: 44px;
  font-weight: 400;
  margin: 0;
  font-family: "Quiche Display Regular", sans-serif;
  line-height: 53.9px;
}
form {
  margin-top: 25px;
}
select:invalid,
body input::placeholder {
  color: #626262;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 400;
  line-height: 19.5px;
}
select option:first-child {
  color: #626262;
}
form input,
form #file-input-label {
  color: #000;
  border-radius: 28px;
  border: 1px solid #d9d9d9;
  padding: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  display: block;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
  font-weight: 400;
  line-height: 19.5px;
  margin-bottom: 20px;
}
form input:active,
form input:hover,
textarea:active,
textarea:hover {
  border: 1px solid #dd2477;
}
input#password-field {
  margin-top: 20px;
}
button.loginbtn {
  margin-top: 20px;
  border-radius: 100px;
  border: transparent;
  padding: 16px 24px;
  font-family: Montserrat;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  color: #fff;
  font-size: 20px;
  width: 100%;
  cursor: pointer;
  box-shadow: 4px 6px 24px 0px #0000001a;
  font-weight: 500;
  line-height: 24.38px;
}
button.loginbtn:hover {
  background: #939393;
}
span.forgot {
  margin-top: 10px;
  display: block;
  font-size: 16px;
  font-family: "Montserrat";
  color: #e02b71;
  font-weight: 400;
  line-height: 19.5px;
}
.divider {
  border: 1px solid #c4c4c4;
}
.login-regstr {
  margin-top: 16px;
  text-align: center;
  font-family: Montserrat;
}
.login-regstr p {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
}
span.color {
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 500;
}
.fa-fw {
  position: absolute;
  top: 37%;
  right: 4%;
  cursor: pointer;
  color: #000;
}
.password-container {
  width: 100%;
  position: relative;
}

button.loginbtn span {
  margin-left: 10px;
}

/*-- End Form Pages --*/

.chat_app_inner_box {
  width: 70%;
  border: 1px solid black;
  margin: 5% auto;
  padding: 15px;
  display: flex;
  gap: 15px;
  border-radius: 10px;
}

.navbar-nav {
  font-family: "Montserrat";
  column-gap: 60px;
}

.main_box {
  border: 1px solid black;
  border-radius: 10px;
  width: 70%;
}

.side_pannel {
  width: 25%;
  border: 1px solid black;
  border-radius: 10px;
  padding: 0px 20px;
}

.chat_user_box {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
  border: 1px solid #e4e4e4;
  padding: 10px;
  border-radius: 15px;
  align-items: center;
  cursor: pointer;
}

.side_panel_profile_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.side_pannel_head {
  background: #ededed;
  height: 30px;
  margin-bottom: 15px;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.side_pannel_head_options_inr_box {
  display: flex;
  gap: 8px;
}

.side_profile_img_box img {
  border-radius: 30px;
  width: 100%;
}

.side_pannel_head_options_inr_box img {
  width: 30px;
  height: 30px;
}

.side_profile_img_box {
  width: 35px;
}

.profile_name_box .chat_msg {
  font-size: 12px;
}

.profile_name_box .profile_name {
  font-weight: 600;
  font-size: 18px;
}

.chat_user_box:hover {
  box-shadow: 10px 20px 20px #f1f1f1;
  transition: 0.2s ease-in;
}

.side_pannel_head_options_inr_box img {
  width: 22px;
  height: 22px;
  margin-top: 4px;
  margin-left: 4px;
}

/*----*/

.LandingPageSection header {
  position: fixed;
  /* background-color: #2c0e1ebf;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px); */
}
.LandingPageSection.homepage header,
.AboutUsPage header {
  box-shadow: none;
}
.LandingPageSection .headerCompo .nav-links {
  color: #fff;
}

.LandingPageSection .headerFixedonScroll .headerCompo .nav-links {
  color: #ffffff;
}

header {
  /* position: fixed; */
  z-index: 1000;
  top: 0px;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  padding: 10px 0px;
  /* border-bottom: 1px solid #E02B71; */
  box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.12);
}

li.nav-item a {
  color: #e02d6f;
}

li.nav-item .loggradientbtn {
  color: #fff !important;
  padding: 10px !important;
}

.scrolled li.nav-item a {
  color: #fff !important;
}

.LandingPageSection .scrolled,
.AboutusPage .scrolled {
  height: auto;
  border-bottom: 2px solid #e91e63;
}

.SubscriptionPage .headerFixedonScroll,
.AboutusPage .headerFixedonScroll,
.LandingPageSection .headerFixedonScroll,
.contactPage .headerFixedonScroll {
  background-color: #2c0e1ebf;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  transition: background-color 0.5 ease;
  position: fixed;
  width: 100%;
  z-index: 9999999;
  top: 0;
  margin-bottom: 80px;
  transition: 0.5s ease-in;
  border-bottom: 2px solid #e91e63;
}

.LandingPageSection .headerFixedonScroll header {
  position: initial;
}

.headerFixedonScroll {
  position: fixed;
  width: 100%;
  z-index: 9999999;
  background-color: #2c0e1ebf;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  transition: background-color 0.5 ease;
  padding: 10px 0px;
}

.LandingPageSection .navbar,
.LandingPageSection .navbar-brand {
  padding: 0px;
}

.LandingPageSection .mainHeader .nav-item a {
  /* line-height: 40px; */
  height: max-content;
  padding: 35px 20px;
  color: #fff;
}

.LandingPageSection .mainHeader .nav-item:hover {
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
}

.LandingPageSection .mainHeader .nav-item.dropdown + .nav-item:hover {
  background: transparent !important;
  border-radius: 30px !important;
}

.LandingPageSection .mainHeader .nav-item ul li a {
  padding: 12px 15px;
  border-bottom: 1px solid #ebebeb;
}

.AboutusPage header {
  position: fixed;
}

.AboutusPage .headerFixedonScroll header {
  position: initial;
}

.AboutusPage header .headerCompo .nav-links {
  color: #fff;
}

/*-headerCompo-*/

.headerCompo .navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: sticky;
  top: 0;
  z-index: 20;
  padding: 0px;
}

.headerCompo .nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1500px;
  position: relative;
}

.headerCompo .nav-logo {
  color: #f5b921;
  align-items: center;
  /* margin-left: 20px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  /* flex-grow: 1; */
  display: flex;
  align-items: center;
  justify-items: center;
  width: 25%;
}

.headerCompo .nav-logo img {
  width: 70%;
}

.headerCompo .nav-logo .icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-left: 16px;
}

.headerCompo .nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  /* margin-right: 2rem; */
  justify-content: space-between;
  width: 75%;
  align-items: center;
  margin: 0;
  padding: 0;
}

.headerCompo .nav-links {
  color: #e91e63;
  text-decoration: none;
  height: 100%;
  border-bottom: 3px solid transparent;
  padding: 35px 15px;
  display: block;
}

.headerFixedonScroll .headerCompo .nav-links {
  color: #fff;
}

.SubscriptionPage header,
.KlickPage header {
  /* border-bottom: 2px solid #e91e63; */
}

/* .fa-code {
  margin-left: 1rem;
} */

.headerCompo .nav-item .active {
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  color: #fff;
}

.klicksPageSection .nav-link {
  padding: 25px;
}

.klicksPageSection .navbar-brand {
  padding: 0;
}

.klicksPageSection .navbar {
  padding: 0px;
  margin: 0px;
}

.klicksPageSection .navbar-nav .nav-item:nth-child(4) a {
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  color: #fff;
  padding: 30px;
}
.contactPageSection .navbar-nav .nav-item:nth-child(3) a {
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  color: #fff;
  padding: 30px;
}

.happystoriePage .navbar-nav .nav-item:nth-child(2) a {
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  color: #fff;
  padding: 30px;
}

.AboutUsPage .navbar-nav .nav-item:nth-child(1) a {
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  color: #fff;
}

.nav .dropdown-item.active,
nav .dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
}

.headerCompo .nav-item {
  height: max-content;
}

.headerCompo .nav-item:hover {
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
}

.headerCompo .nav-item:hover a {
  color: #fff !important;
}

.headerCompo .nav-icon {
  display: none;
  color: #f5b921;
}

.headerCompo .nav-icon .icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

.headerInte header {
  position: inherit;
  padding: 0px 0px;
  border-bottom: 3px solid #e02b71;
}

.headerInte .headerCompo .nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  /* margin-right: 2rem; */
  width: 100%;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 5px;
  justify-content: end;
}

.abc .headerCompo .nav-logo {
  color: #f5b921;
  align-items: center;
  /* margin-left: 20px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  /* flex-grow: 1; */
  display: flex;
  align-items: center;
  justify-items: center;
  /* width: 25%; */
}

.headerInte .headerCompo .nav-item {
  line-height: normal;
}

.headerInte .headerCompo .nav-container {
  height: 100px;
}

.headerInte .headerCompo .nav-links {
  padding: 40px 21px;
  color: #dd2477 !important;
  font-size: 17px;
}

.headerInte .headerCompo .navbar {
  padding: 0;
}

.abc .headerCompo .nav-links:hover {
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  color: #fff !important;
}

.masthead {
  position: relative;
  height: 100vh;
}

.masthead .masthead__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.masthead .masthead__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
}

.logo img {
  height: 100px;
}

.nav_link ul {
  display: flex;
  justify-content: end;
  list-style: none;
  gap: 40px;
  color: #fff;
  align-items: center;
  height: 100px;
}

.minus-x {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

/* nav ul li a {
  color: #fff !important;
} */

nav ul li ul li a {
  color: #000 !important;
}

.loggradientbtn {
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  padding: 10px;
  width: 100px;
  text-align: center;
  border-radius: 30px;
}

.masthead__p {
  position: absolute;
  bottom: 20%;
  text-align: center;
  width: 100%;
}

.masthead__p p {
  font-size: 45px;
  color: #fff;
  margin-bottom: 0px;
}

.masthead__p .heroRegistraionButton a {
  color: #fff;
  text-decoration: none;
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  padding: 10px 20px;
  text-align: center;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: 500;
}

.heroRegistraionButton {
  margin-top: 40px;
}

.side_panel_head_profile_img {
  width: 60%;
}

.side_panel_head_profile_img_sml {
  width: 50%;
}

.serviceBox {
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  padding: 80px 0px;
  text-align: center;
  color: #fff;
}

.serviceinrheader {
  padding-bottom: 30px;
  margin-bottom: 45px;
  border-bottom: 1px solid #ffffff6e;
}

.serviceIcnBoxicn {
  margin-bottom: 15px;
}

.serviceIcnBoxtitle p {
  font-size: 22px;
  font-family: "Montserrat";
  font-weight: 500;
}

.optionTextBarBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 20px 60px 50px;
  border-bottom: 1px solid #d4d4d4;
  margin: 20px 0px;
}

.felxdirection_row-reverse {
  flex-direction: row-reverse;
  padding: 60px 0px 60px 0px;
  justify-content: flex-end;
}

/*-- CTA Box ---*/
.ctaboxmain {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  padding: 80px 330px 75px 330px;
  text-align: center;
  color: #fff;
}

.plans_otrBox .plansBx {
  width: auto;
}

.plansBx ul li:last-child {
  display: flex;
}

.SubscriptionSection .plansBx ul li:last-child {
  display: block;
  margin-bottom: 35px;
}

.SubscriptionSection .plans_otrBox .plansBx {
  width: 22%;
}

.plans_otrBox {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
}

.plansBx {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  border-radius: 20px;
  padding: 35px;
  color: #fff;
  font-family: "Montserrat";
  height: fit-content;
}

.plansBx .planHaeading {
  text-align: center;
  color: #fff;
}

.plansBx ul li button i {
  font-size: 16px;
  margin-left: 5px;
}

.plansBx ul li span {
  font-weight: 600;
}

.plansBx ul li > div {
  display: flex;
  align-items: center;
}

.plansBx ul li > div img {
  height: fit-content;
}

.plansBx ul li {
  line-height: normal;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

/* .plansBx ul li:last-child {
  display: block;
  margin-bottom: 35px;
} */

.plansBx ul li p {
  margin-bottom: 0px;
  line-height: 22px;
}

#navbar-fixed.scrolled {
  background-color: #2c0e1ebf;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  transition: background-color 0.5 ease;
  padding: 0px 0px;
  height: auto;
}

section.offerbar ul {
  display: flex;
  justify-content: space-around;
  color: #e02b71;
  border-bottom: 1px solid #e02b71;
  border-top: 1px solid #e02b71;
  padding: 25px 0px;
}

.SubscriptionSection section.offerbar ul {
  color: #f2663b;
  border-bottom: 1px solid #f2663b;
  border-top: 1px solid #f2663b;
}

.securityReasonmainbx {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  padding: 80px 0px;
  color: #fff;
}

.imgtextbximg img {
  width: 100%;
}

.optionTextIcnBar a img {
  width: 100px;
}

/*- common style-*/
.mt50 {
  margin-top: 50px;
}

.rowReverse {
  flex-direction: row-reverse;
}

.mln50 {
  margin-left: -55px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.alignItemCenter {
  align-items: center;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.justifyContentEnd {
  justify-content: end;
}

.textrightalign {
  text-align: right;
}

.py25 {
  padding: 40px 0px;
}

.dflex {
  display: flex;
  flex-wrap: wrap;
}

.commanheading {
  color: #f2663b;
  font-size: 42px;
}

.fontColorWhite {
  color: #fff;
}

.mr90 {
  margin-right: 90px;
}

.borderNone {
  border: none !important;
}

.borderNone:has(.optionTextBar) p {
  padding-right: 60px;
}
.ctaboxtext a {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  color: #fff;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  border: 1px solid #fff;
  box-shadow: 0px 6px 40px 0px #ffffff5e;
  font-family: "Montserrat";
  font-weight: 600;
  display: inline-block;
}

.ctaboxtext h5 {
  margin-bottom: 30px;
}

.imgtextsctn {
  padding: 90px 0px 60px 0px;
}

.commandescription {
  font-family: "Montserrat";
  font-size: 18px;
}
section.offerbar {
  margin: 70px 0px;
  font-size: 18px;
}

section.offerbar ul li {
  font-weight: 600;
  font-family: "Montserrat";
  padding-right: 150px;
}

.sclicnbx {
  margin-top: 45px;
}

.sclicnbx a {
  text-decoration: none;
  margin: 0px 10px;
}

.sclicnbx a:nth-child(1) {
  margin-left: 0;
}

.planHaeading p {
  font-family: "Quiche Display Regular";
  font-size: 24px;
  border-bottom: 1px solid #fff9;
  padding-bottom: 8px;
}

.sbscrptninrBox p {
  padding-right: 229px;
  font-size: 45px;
}

.imgtextsctnmain {
  overflow: hidden;
}

.imgtextsctnmain > .container {
  /* border-bottom: 1px solid #f36e36; */
  padding-bottom: 60px;
}

.plansBx ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.plansBx ul li img {
  margin-right: 10px;
}

.securityReasoninrbx .commanheading {
  text-align: center;
}

.securityReasoninrbx ul li {
  margin-bottom: 40px;
  display: flex;
  font-size: 18px;
}

.securityReasoninrbx ul li:nth-child(3) {
  margin-bottom: 0;
}

.securityReasoninrbx .commanheading {
  text-align: center;
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
  margin-bottom: 60px;
}

.securityReasoninrbx ul {
  list-style-type: none;
  width: 65%;
  margin: auto;
  font-family: "Montserrat";
}

.securityReasoninrbx ul li span {
  background: #fff;
  padding: 15px;
  color: red;
  border-radius: 30px;
  margin-right: 15px;
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
}

.small_heading {
  font-family: "Montserrat";
  font-size: 18px;
  margin-top: 15px;
}

/*--  --*/

.detail-title {
  display: flex;
  align-items: self-end;
  gap: 10px;
}

.detail-titleTwo {
  display: flex;
  align-items: self-end;
  gap: 10px;
  justify-content: space-between;
}

.fixpadding {
  padding: 40px 15px 30px 0px;
}

#progressbar {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
}

#progressbar::after {
  position: absolute;
  top: 16px;
  right: 10px;
  bottom: 0;
  left: 0px;
  content: " ";
  background: #d9d9d9;
  height: 3px;
  z-index: -1;
}

.progress-mobile::after {
  background: linear-gradient(45deg, #dd2477, #f36e36) !important;
}

#progressbar::before {
  position: absolute;
  top: 16px;
  right: 0px;
  bottom: 0;
  left: 0px;
  content: " ";
  background: linear-gradient(45deg, #dd2477, #f36e36);
  height: 3px;
  z-index: 0;
  width: 12%;
}

#progressbar li {
  position: relative;
  list-style: none;
}
#progressbar li .complete {
  background: -webkit-linear-gradient(45deg, #dd2477, #f36e36) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

#progressbar li .fa-heart {
  font-size: 37px;
  background: #d9d9d9;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#progressbar li a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  content: " ";
}

.reg-right-txt {
  display: flex;
  justify-content: end;
  text-align: center;
  font-family: "Montserrat";
}

.reg-centr-txt {
  text-align: center;
}

.reg-centr-txt p {
  font-family: "Montserrat";
}

.reg-right-txt h2 {
  background: -webkit-linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
  font-size: 26px;
}

#progressbar li:nth-child(1) {
  margin-left: 100px;
}

.family #progressbar li:nth-child(1) {
  left: calc(80% - 100px);
}

.family #progressbar::before {
  width: 82%;
}

.additional #progressbar li:nth-child(1) {
  left: calc(98% - 100px);
}

.additional #progressbar::before {
  width: 100%;
}

.Verify_link {
  position: relative;
}

.Verify_link span {
  position: absolute;
  top: 10px;
  /* z-index: 99999; */
  right: 15px;
  border-radius: 13px;
}

.Verify_link .close {
  color: #e94d53;
  font-size: 12px;
  margin-top: 3px;
  font-weight: 800;
}

#msform {
  margin-top: 20px;
}

#msform .dflex {
  gap: 10px;
}

#msform .dflex div {
  width: 100%;
}

.prsl-detail-heading {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
}

.reg-icon img {
  font-size: 10px;
  width: 40px;
}

#msform .divider {
  margin: 70px 0px 30px 0px;
}

.btn-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cont-btn i {
  margin-left: 8px;
}

.commonBtn {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  color: #fff;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  border: 1px solid #fff;
  box-shadow: 0px 6px 40px 0px #ffffff5e;
  font-family: "Montserrat";
  font-weight: 600;
}

.registration {
  margin: 0px 0px 60px 0px;
}

#msform .left-btn i,
#msform .left-btn {
  margin-right: 6px;
  background: -webkit-linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#msform .left-btn {
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
}

.Verify_link span a {
  color: #212121;
  font-family: "Montserrat";
  font-weight: 500;
}

#msform .dropdown-menu {
  display: initial !important;
  position: initial !important;
  color: #000;
  border-radius: 28px;
  border: 1px solid #d9d9d9;
  padding: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  display: block;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
  font-weight: 400;
  line-height: 19.5px;
  margin-bottom: 20px;
}

.prsl-detail {
  margin-bottom: 60px;
}

.reg-forms .border {
  margin-top: 30px;
  margin-bottom: 30px;
}

.upltxt_field[type="text"] {
  position: relative;
}

.upltxt_field .upload_field[type="text"] {
  position: absolute;
  padding: 0px;
  border: 0px !important;
}

.reg-forms .js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.reg-forms .inputfile + label {
  max-width: 80%;
  font-size: 1.25rem;
  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.reg-forms .no-js .inputfile + label {
  display: none;
}

.reg-forms .inputfile:focus + label,
.reg-forms .inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.reg-forms .inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

.upltxt_field {
  position: relative;
}

.custom-file {
  position: relative;
  font-family: arial;
  overflow: hidden;
  margin-bottom: 10px;
  width: auto;
  display: inline-block;
  padding: 10px;
}
.custom-file-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 100;
}
.custom-file img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
ul.file-list {
  font-family: arial;
  list-style: none;
  padding: 0;
}
ul.file-list li {
  border-bottom: 1px solid #ddd;
  padding: 5px;
}
.remove-list {
  cursor: pointer;
  margin-left: 10px;
}

.upldicn2 {
  position: absolute;
  top: -2px;
  right: 0px;
  background: #96969600;
  width: auto;
  padding: 15px;
  border-radius: 45px;
}

.upldicn2 label img {
  width: 20px;
}

.upldicn {
  position: absolute;
  top: -2px;
  right: 5px;
  background: #96969600;
  width: fit-content;
  padding: 15px;
  border-radius: 45px;
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.upltxt_field input {
  padding-right: 60px;
}

.upltxt_field input .upldicn > label img {
  cursor: pointer;
  width: 5px;
}

.addressForm2 .upldicn {
  width: fit-content;
}

svg {
  cursor: pointer;
} /* whole rectangle */

.upldicn label:hover img {
  cursor: pointer;
}

.upldicn label img {
  width: 20px;
  cursor: pointer;
}

.reg-icon {
  display: flex;
  align-items: end;
  gap: 15px;
}

.chkbox {
  display: flex;
  gap: 10px;
  font-family: "Montserrat";
  margin-bottom: 20px;
}

.chkbox input {
  width: auto;
  margin: 0;
}

.chkbox input[type="checkbox"] {
  width: 25px;
  height: 25px;
}

.reg-form .border {
  margin-top: 30px;
  margin-bottom: 30px;
}

.prsl-detail textarea {
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #d9d9d9;
  font-family: "Montserrat";
  margin-bottom: 10px;
}

.left-btn-dscrptn {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
}

.terms {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 550;
  background: linear-gradient(
    45deg,
    rgba(221, 36, 119, 0.1) 0%,
    rgba(243, 110, 54, 0.1) 100%
  );
  border: 2px solid rgba(243, 110, 54, 1);
  border-radius: 10px;
  padding: 15px;
}

.terms input,
.terms p {
  margin: 0;
}

.terms input {
  width: 45px;
  height: 45px;
}

.left-btn-n-dscrptn.space {
  margin: 35px 0px;
}

/*--  Loader --*/

.vh100 {
  height: 100vh;
}

.fullbodyLoaderBgColor {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  height: 100vh;
}

.ftrText {
  position: absolute;
  bottom: 5%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  display: block;
  width: 100%;
  font-family: "Montserrat";
}

input[type="checkbox"] {
  accent-color: red;
}

.addressForm #progressbar li:nth-child(1) {
  margin-left: 46%;
}

.addressForm #progressbar::before {
  width: 50%;
}

.familyInfo #progressbar li:nth-child(1) {
  margin-left: 65%;
}

.familyInfo #progressbar::before {
  width: 66%;
}

.AdditionalInfo #progressbar li:nth-child(1) {
  margin-left: 97%;
}

.AdditionalInfo #progressbar::before {
  width: 99%;
}

.EducationWorkfrom #progressbar li:nth-child(1) {
  margin-left: 23%;
}

.EducationWorkfrom #progressbar::before {
  width: 25%;
}

/* 
SELECT {
  /* background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.00072 5.17217L11.9507 0.222168L13.3647 1.63617L7.00072 8.00017L0.636719 1.63617L2.05072 0.222168L7.00072 5.17217Z' fill='#626262'/></svg>") no-repeat; */
/* background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat; */

/* background: url("../src/assets/images/select_dropdown_icon.svg"); */
/* background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance:none !important;
  -webkit-appearance: none !important; 
  appearance: none !important;
  padding-right: 2rem !important; 
} 
*/

.pdx40 {
  padding-right: 40px;
  padding-left: 40px;
}

.imgRotate {
  animation: rotation 6s infinite linear;
  position: relative;
}

img.loading4icon_heart {
  position: absolute;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login_form select.dropdown-menu {
  position: relative;
  width: 100%;

  color: #000;
  border-radius: 28px;
  border: 1px solid #d9d9d9;
  padding: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  display: block;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
  font-weight: 400;
  line-height: 19.5px;
  margin-bottom: 20px;
}

.mailIcon i {
  font-size: 152px;
  color: #fff;
}

.ftrText a {
  color: #fff;
  margin-left: 5px;
  font-size: 20px;
  font-weight: 700;
}

.ftrText a:hover {
  margin-left: 10px;
  transition: 0.5s ease;
}

.headerstylefLi {
  min-width: fit-content;
}

.headerstyleSLi {
  border-bottom: 2px solid #e02b71;
  min-width: 100%;
}

.headerstyle {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.headerstyle li p {
  margin: 0px;
  padding: 0px 40px 0px 0px;
  line-height: 0.5;
}

.headerstyle.rowReverse li p {
  margin: 0px;
  padding: 0px 0px 0px 40px;
  line-height: 0.5;
}

.pl45 {
  padding-left: 45px;
}

/*-- about Us page --*/

.founderCeo {
  margin-bottom: 50px;
}

.sprHeading {
  margin-top: 100px;
  margin-bottom: 100px;
}

.spacer40 {
  margin-top: 190px;
}

.aboutus .imgtextsctnmain {
  overflow: hidden;
}

.aboutus .imgtextsctnmain > .container {
  padding-bottom: 0px;
}

/*-- footer compo --*/

.MatchesPage footer {
  margin-top: 40px;
}

footer {
  margin-top: 100px !important;
}

.ftrSclLinks {
}

.ftrSclLinks + p {
  text-align: right;
}

.ftrSclLinks ul {
  display: flex;
  list-style: none;
  gap: 20px;
}

.ftrinrbx {
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  font-family: "Montserrat";
  font-weight: 500;
  grid-template-columns: 50% 50%;
}

.ftrSclLinks li img {
  width: 30px;
}

footer {
  border-top: 1px solid #e02b71;
}

/*-- blog page --*/

.BlogCardMainBox .BlogCardimg {
  height: 260px;
}

.BlogCardMainBox {
  border-radius: 30px;
  background: #fff;
  overflow: hidden;
  box-shadow: 20px 20px 30px #e9e9e9;
  font-family: "Montserrat";
  margin-bottom: 30px;
}

.BlogCardSection {
  margin-top: 50px;
}

.BlogCardBox .card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  border: none;
}

.BlogCardBox .card img {
  width: 100%;
  height: auto;
  display: block;
}

.BlogCardBox .card-content {
  padding: 20px;
}

.BlogCardBox .card-content h3 {
  margin-top: 0;
  font-size: 18px;
  color: #333;
}

.BlogCardBox .card-content .card-dscptn {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
  color: #000;
}
/* 
.BlogCardBox .card-content p {
  color: #666;
  margin-bottom: 10px;
} */

.BlogCardBox .card-content .price {
  font-size: 20px;
  color: #ff6600;
  font-weight: bold;
  margin-bottom: 10px;
}

.BlogCardBox .btn {
  display: inline-block;
  background-color: #ff6600;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.BlogCardBox .date {
  font-weight: 400;
}

.BlogCardBox .card-content .arowImg img {
  width: 25px;
}

.BlogCardImgBox {
  height: 330px;
  overflow: hidden;
}

.blogPgHdng {
  border-bottom: 1px solid #dd2477;
}

.blogPgHdng p {
  text-decoration: none;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  text-align: center;
  font-size: 38px;
}

/*-- Klick page --*/

.video-player {
  /* margin-top:10px; */
  object-fit: cover !important;
  border-radius: 10px !important;
}

.video-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

video {
  border-radius: 5px;
  scroll-snap-align: start;
}

/*-- Blog Detail ---*/

.blogDtlPgHdngBox a img {
  transform: rotate(180deg);
  cursor: pointer;
}

.blogDtlPgHdngBox {
  margin-top: 180px;
}

.blogDtlPgHdngBox .headingPage {
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 38px;
  margin-top: 40px;
  margin-bottom: 10px;
  width: 60%;
}

.blogDtlPgHdngBox .blogDtlPgDate {
  font-family: "Montserrat";
  font-weight: 500;
  color: #626262;
}

.blogDtlPara {
  font-family: "Montserrat";
  margin: 80px 0px;
}

/*-- logInHeader --*/

/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add an active class to highlight the current page */
.topnav a.active {
  background-color: #04aa6d;
  color: white;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}

.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal {
  border-radius: 10px;
}

/*---- Contact Form -----*/

.contactUsFormBox {
  margin: 60px 0px 60px 40px;
}

.contactUsFormBox {
  margin: 60px 0px 60px 30px;
}

.fillgraidentandcenter {
  background: linear-gradient(45deg, #dd2477, #f36e36);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.contactPageSection .container .row:nth-child(2) {
  margin: 120px 0px;
}

.contactform textarea {
  color: #000;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  padding: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  display: block;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
  font-weight: 400;
  line-height: 19.5px;
  margin-bottom: 20px;
}

.contactMailIcntext img {
  margin-right: 10px;
  width: 20px;
}

.contactMailIcntext,
.contactwhatsapplIcntext {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
}

.contactwhatsapplIcntext {
  display: flex;
  gap: 10px;
}

.contactwhatsapplIcntext span {
  display: block;
  margin-top: 4px;
}

.brdrRight {
  position: relative;
}

.brdrRight::after {
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 10px;
  left: 0;
  content: " ";
  border-right: 1px solid #d9d9d9;
}

.contactPageSection .commanheading {
  color: #000;
}

.contactPageSection .contactSubHeading {
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}

.contactform .loginbtn {
  width: max-content;
  padding: 10px 30px;
  font-size: 17px;
}

.contactUsPreBox #progressbar li:nth-child(1) {
  margin-left: 95%;
}

.contactUsPreBox #progressbar::before {
  width: 100%;
}

.contactPageSection .container .row:nth-child(1) {
  box-shadow: 3px 3px 20px 20px #c4c4c426;
  border-radius: 20px;
  margin-top: 40px;
}

.contactPageSection .container .row:nth-child(1) .contactUsPreBox {
  padding: 40px;
}

.setheight {
  height: 40px;
  margin-top: 78px;
}

/*----- Contact Page -----*/
.section-testmonials {
  font-family: Poppins;
  display: flex;
  gap: 20px;
  align-items: center;
}

.column-testmonials {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 20%;
}

.section-details {
  border-left: 10px solid #ffcb00;
  padding-left: 20px;
}

.name-section {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;
  color: #650061 !important;
  line-height: 1.2em;
  margin: 0;
}

.title-section b {
  color: #650061;
}

.title-section {
  color: #000;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.navigation-testmonials {
  display: flex;
  gap: 10px;
}

.swiper-testmonials {
  width: 80%;
  overflow: hidden;
  position: relative;
}

.swiper-testmonials .swiper-slide {
  border-radius: 10px;
  background: linear-gradient(118deg, #7c1a78 10.88%, #43044a 83.88%);
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  transform: scale(0.9) !important;
  filter: blur(1px) grayscale(0.5);
  transition: 0.2s;
}

.swiper-testmonials .swiper-slide * {
  margin: 0;
}

.swiper-testmonials .swiper-slide-active {
  filter: blur(0px) !important;
  transform: scale(1) !important;
}

.card-slide {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.head-slide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-slide {
  display: flex;
  align-items: center;
  gap: 20px;
}

.title-slide h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.title-slide h6 {
  color: #ffcb00;
  font-size: 14px;
  font-weight: 500;
}

.text-slide {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.swiper-button-testmonials-next,
.swiper-button-testmonials-prev {
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  /* Add your custom styles here */
  color: #007bff; /* Change the color to your preference */
  font-size: 20px;
  cursor: pointer;
}

.CustomModal {
  max-width: 100% !important;
  width: 100% !important;
  min-height: 100% !important;
}

.CustomModal .image-container {
  display: grid;
  flex-wrap: wrap;
  margin-top: 70px;
  gap: 30px;
  grid-template-columns: 31% 31% 31%;
}

.CustomModal .image-preview-container {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  overflow: visible;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 10px #d6d6d6;
}

.CustomModal .remove-button {
  position: absolute;
  top: -14px;
  right: -15px;
  padding: 3px 10px;
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 2px solid red;
  box-shadow: -4px 4px 20px 4px #919191ab;
}

.CustomModal .close-button {
  position: absolute;
  right: 20px;
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%) !important;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 17px;
  width: 100px;
  height: 50px;
  padding: 0px;
  border-radius: 50px;
  gap: 7px;
}

.viewBtnDiv .btn-trans {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  color: #fff !important;
  padding: 6px 25px;
  border-radius: 50px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

/* .error-msg:has(.viewBtnDiv) {
  justify-content: end;
} */

.startLiveVideoBox .btn-trans {
  gap: 10px;
  display: flex;
  align-items: center;
  padding: 0px;
}

.videoRecorderBox {
  display: block;
  text-align: center;
  margin-top: 60px;
}

.videoinerbox {
  display: flex;
  align-items: baseline;
  gap: 30px;
  margin-top: 20px;
}

.videoRecorderFooter {
  display: flex;
  justify-content: center;
}

.videoRecorderFooter button {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  color: #fff;
  text-decoration: none;
  padding: 10px 40px;
  border-radius: 40px;
  border: 1px solid #fff;
  box-shadow: 0px 6px 40px 0px #ffffff5e;
  font-family: "Montserrat";
  font-weight: 600;
}

.videoRecorderFooter p {
  font-family: "Montserrat";
}

.arrobxline button {
  padding: 35px 15px;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%) !important;
}

.registrationFree input#otp {
  margin: auto;
}

#root {
  position: relative;
}

.AudioToggleButton {
  position: fixed;
  z-index: 999;
  right: 35px;
  bottom: 35px;
}

.registrationFreeViewBtn .btn-trans {
  color: #1c1c1c;
  font-size: 14px;
  margin: 15px 0px;
  text-align: center;
  display: block;
  width: 100%;
}

.registrationFree input#otp + .error-msg {
  margin-top: 7px;
}

.requiredDocsPage .terms {
  display: block;
  margin-top: 80px;
  padding: 15px 25px 5px 25px;
  background: #fff7f3;
  width: 80%;
}

.requiredDocsPage .terms ul {
  margin-top: 20px;
}

.requiredDocsPage .terms p {
  font-size: 21px;
  line-height: 33px;
}

.requiredDocsPage .terms ul li {
  font-weight: 500;
  font-size: 15px;
  margin-top: 5px;
}

.customAestrics {
  color: red;
}

.requiredDocsPage .upltxt_field {
  margin-bottom: 30px;
}

.klicksPageSection header {
  /* border-bottom: 2px solid #ee613f; */
}

.videoPerviewModal .videoRecorderFooter {
  display: block;
}

.ReactModal__Overlay {
  z-index: 1000; /* Or any high enough value */
  background-color: rgb(255 246 246);
}

.videoRecorderBox .prsl-detail-heading {
  background: -webkit-linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
}

.videoPerviewModal .videoRecorderFooter button {
  margin: 10px;
}

.videoRecorderBox video {
  border-radius: 25px;
  border: 2px solid red;
  /* box-shadow: 1px 6px 20px 20px #e0dfdf; */
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../src/assets/images/select_dropdown_icon.svg");
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

select option {
  padding: 40px !important;
}

.paginationAlign {
  display: flex;
  justify-content: center;
  margin-top: -30px;
  margin-bottom: 20px;
}

.paginationAlign .active > .page-link {
  z-index: 3;
  color: #dd2477;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  border-color: #fff;
}

.paginationAlign .active > .page-link,
.page-link.active {
  z-index: 3;
  color: #fff;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  border-color: #dd2477;
}

/* .paginationAlign .page-item:first-child .page-link,
.paginationAlign .page-item:last-child .page-link {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
} */

.paginationAlign .page-link {
  color: #dd2477;
}

.errorPageMsg {
  text-align: center;
  color: #fff;
}

.errorPageMsg p {
  font-size: 21px;
  width: 80%;
  margin: auto;
  font-weight: 400 !important;
}

.requiredDocsPage .viewBtnDiv .btn-trans {
  color: #fff !important;
}

.PersonalFormRegistraion .Verify_link .btn-trans,
.familyInformationRegistration .Verify_link .btn-trans,
.addtionalPage .Verify_link .btn-trans {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 14px;
  color: #fff !important;
}

.registrainHeader .reg-logo img {
  cursor: pointer;
}

.notificationTime {
  font-size: 14px;
  margin-bottom: 0px;
}

.NotificationTitle {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 25px;
}

.notificationCard {
  background: #fff;
  border-radius: 20px;
  padding: 35px 25px 0px 25px;
  box-shadow: 4px 4px 20px 20px #f3f3f3;
  height: 65vh;
  overflow-y: scroll;
  margin-bottom: 60px;
}

.notificationCard::-webkit-scrollbar {
  background-color: transparent;
  width: 1px;
}

.notificationCard ul li:nth-last-child(1) {
  margin-bottom: 0;
  padding-bottom: 0;
}

.ftrTextTitle {
  font-size: 32px;
  font-weight: 300;
}

.pausePlayButton {
  position: absolute;
  top: 50%;
  left: 51%;
  transform: translate(-150%, -50%);
  z-index: 1;
}

/* start music toggle*/
/* The switch - the box around the speaker*/
.toggleSwitch {
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #dd2477, #f36e36) !important;
  border-radius: 50%;
  cursor: pointer;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
  overflow: hidden;
}

/* Hide default HTML checkbox */
#checkboxInput {
  display: none;
}

.bell {
  width: 18px;
}

.bell path {
  fill: white;
}

.speaker {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition-duration: 0.3s;
}

.speaker svg {
  width: 18px;
}

.mute-speaker {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 3;
  transition-duration: 0.3s;
}

.mute-speaker svg {
  width: 18px;
}

#checkboxInput:checked + .toggleSwitch .speaker {
  opacity: 0;
  transition-duration: 0.3s;
}

#checkboxInput:checked + .toggleSwitch .mute-speaker {
  opacity: 1;
  transition-duration: 0.3s;
}

#checkboxInput:active + .toggleSwitch {
  transform: scale(0.7);
}

#checkboxInput:hover + .toggleSwitch {
  background: linear-gradient(45deg, #dd2477, #f36e36);
}

/*end music toggle*/
.whatsapp-icon {
  color: #25d366; /* WhatsApp green */
  font-size: 24px; /* Adjust size as needed */
}

.registerPage .login-logo {
  margin-bottom: calc(100% / 11);
}

.loginPage .login-logo,
.forgot-password .login-logo,
.changePasswordForm .login-logo {
  margin-bottom: calc(100% / 4);
}

.termNcondition {
  cursor: pointer;
  font-weight: 700;
  color: #dd2477 !important;
}

.acnlk .CustomModal {
  max-width: 80% !important;
  width: 80% !important;
  min-height: 80% !important;
  margin-top: 40px;
  margin-bottom: 40px;
}

.terms-container {
  font-family: "circular";
}

.terms-container h2 {
  font-weight: 800;
}
.login_form.main-login .login-logo {
  text-align: center;
}
.exact_income {
  font-family: "Montserrat";
}
footer .copyright,
footer .ftrSclLinks ul {
  margin: 0;
  padding: 0;
}
footer .ftrinrbx .Passionately {
  display: none;
}

.mysubscription .settingForm .submitIcon {
  margin-top: 5px;
  margin-bottom: 0px;
}

.settingLabel {
  margin: 38px 0px 18px 0px;
  font-size: 22px;
  line-height: 0;
}

.mbn18 {
  margin-bottom: -18px;
}

.aboutDetail ul li {
  gap: 3px;
}

.no-select {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
